import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { MotherWebsiteText } from 'styles/Typography.styled';
import { Swiper } from 'swiper/react';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
    align-items: flex-start;
    height: 100%;
    min-height: 100vh;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
`;

export const ModalWrapper = styled.div(
    ({ theme: { colors, fontSizes } }) => css`
        align-items: flex-start;
        background-color: ${colors.darkBlue};
        height: 100%;
        min-height: 100vh;
        left: 0;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;

        &:before {
            background-color: ${colors.white};
            border-radius: 0px 0px 0px 85px;
            content: '';
            height: 82%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            background: ${colors.white};
            font-size: ${fontSizes.f18};
            height: 450px;
            text-align: center;

            ${mediaQueries.xs} {
                height: 268px;
            }
        }

        .swiper-slide-active {
            height: 530px;
            text-align: center;
            font-size: 18px;
            background: ${colors.white};

            ${mediaQueries.xs} {
                height: 330px;
            }
        }
    `,
);

export const Content = styled.div`
    padding: 95px 20px;
    display: grid;
    position: relative;
    z-index: 1;

    ${mediaQueries.xs} {
        padding: 85px 20px 24px;
    }
`;
export const HeaderWrapper = styled.div`
    width: 100%;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledLink = styled(Link)`
    text-decoration: none;
`;

export const CloseButton = styled.div`
    cursor: pointer;
`;

export const ModalHeader = styled.div`
    width: 100%;
    display: grid;
`;

export const TitleWrapper = styled.div`
    padding-bottom: 24px;
`;

export const ModalTitle = styled.h2(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        padding-bottom: 12px;
        color: ${colors.darkBlue};
        font-size: ${fontSizes.f30};
        font-weight: ${fontWeight.semiBold};
        line-height: 36px;
    `,
);

export const AdressText = styled(MotherWebsiteText)(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        color: ${colors.motherText};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.m};
        line-height: 22px;
        text-transform: uppercase;
    `,
);

export const StyledText = styled(MotherWebsiteText)`
    max-width: 490px;
`;

export const PlayerWrapper = styled.div`
    height: 100%;
`;

export const SwiperWrapper = styled(Swiper)`
    height: 530px;
    margin-bottom: 24px;

    ${mediaQueries.xs} {
        height: 330px;
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
