import React, { ReactChild } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { Wrapper, MapWrapper } from './BaseMap.styled';
import { MarkerType } from 'types/CommonTypes';
import { MapMarker } from 'components/MapMarker/MapMarker';
interface IBaseMapProps {
    mapPosition: {
        lat: number;
        lng: number;
    };
    zoom: number;
    markers: MarkerType[];
    mapStyles: any;
    children?: ReactChild;
    isMarkerClickable?: boolean;
}

export const BaseMap = ({
    mapPosition,
    zoom,
    markers,
    mapStyles,
    children,
    isMarkerClickable,
}: IBaseMapProps) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDGFrbHrfmnch0LJEtIERQzNR1WeEU0ebw',
    });

    return (
        <Wrapper>
            {isLoaded && (
                <>
                    <MapWrapper>
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '100%',
                            }}
                            zoom={zoom}
                            center={mapPosition}
                            options={{
                                styles: mapStyles,
                                mapTypeControl: false,
                                streetViewControl: false,
                                fullscreenControl: false,
                            }}
                        >
                            {markers?.map((marker) => {
                                return (
                                    <>
                                        <MapMarker
                                            marker={marker}
                                            isMarkerClickable={
                                                isMarkerClickable
                                            }
                                        />
                                    </>
                                );
                            })}
                        </GoogleMap>
                    </MapWrapper>
                    {children && children}{' '}
                </>
            )}
        </Wrapper>
    );
};
