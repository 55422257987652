import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { MotherWebisteCloseIcon } from 'shared/svg/closeIcon';
import ReactPlayer from 'react-player';
import {
    AdressText,
    CloseButton,
    Content,
    ModalGalleryWrapper,
    ModalHeader,
    ModalTitle,
    ModalWrapper,
    StyledText,
    TitleWrapper,
    SwiperWrapper,
    Image,
    ThumbsWrapper,
} from './RealizationModal.styled';
import Swiper, { FreeMode, Navigation, Thumbs } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { RealizationType } from 'types/CommonTypes';
import {
    MotherWebsiteSliderArrow,
    MotherWebsiteSliderArrowHovered,
} from 'shared/svg/arrows';

export interface RealizationModalProps {
    modalIsOpen?: boolean;
    realization?: RealizationType;
    setModalIsOpen: (value: boolean) => void;
}

export const RealizationModal = ({
    realization,
    modalIsOpen,
    setModalIsOpen,
}: RealizationModalProps) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<Swiper>();
    const [isPrevBtnHovered, setIsPrevBtnHovered] = useState(false);
    const [isNextBtnHovered, setIsNextBtnHovered] = useState(false);

    return modalIsOpen
        ? createPortal(
              <ModalWrapper onClick={() => setModalIsOpen(false)}>
                  <Content
                      onClick={(e) => {
                          e.stopPropagation();
                      }}
                  >
                      <CloseButton onClick={() => setModalIsOpen(false)}>
                          <MotherWebisteCloseIcon />
                      </CloseButton>
                      <ModalHeader>
                          {' '}
                          <TitleWrapper>
                              <ModalTitle>
                                  {realization?.realizationName}
                              </ModalTitle>
                              <AdressText>
                                  ADRES: {realization?.realizationAddress}
                              </AdressText>
                          </TitleWrapper>
                          <StyledText>
                              {realization?.realizationDescription}
                          </StyledText>
                      </ModalHeader>
                      <ModalGalleryWrapper>
                          <div
                              className="swiper-button-prev swiper-button"
                              onMouseOver={() =>
                                  setIsPrevBtnHovered(!isPrevBtnHovered)
                              }
                              onMouseOut={() =>
                                  setIsPrevBtnHovered(!isPrevBtnHovered)
                              }
                          >
                              {isPrevBtnHovered ? (
                                  <MotherWebsiteSliderArrowHovered />
                              ) : (
                                  <MotherWebsiteSliderArrow />
                              )}
                          </div>
                          <div
                              className="swiper-button-next swiper-button"
                              onMouseOver={() =>
                                  setIsNextBtnHovered(!isNextBtnHovered)
                              }
                              onMouseOut={() =>
                                  setIsNextBtnHovered(!isNextBtnHovered)
                              }
                          >
                              {isNextBtnHovered ? (
                                  <MotherWebsiteSliderArrowHovered />
                              ) : (
                                  <MotherWebsiteSliderArrow />
                              )}
                          </div>
                          <SwiperWrapper
                              navigation={{
                                  nextEl: '.swiper-button-next',
                                  prevEl: '.swiper-button-prev',
                              }}
                              thumbs={{ swiper: thumbsSwiper }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiperUpper"
                          >
                              {realization?.realizationGallery.map(
                                  (image, index) => (
                                      <SwiperSlide key={index}>
                                          {image.type === 'video' ? (
                                              <ReactPlayer
                                                  width={'100%'}
                                                  height={'100%'}
                                                  controls
                                                  url={image.video}
                                              ></ReactPlayer>
                                          ) : (
                                              <Image
                                                  src={image?.image?.sourceUrl}
                                              />
                                          )}
                                      </SwiperSlide>
                                  ),
                              )}
                          </SwiperWrapper>
                          <ThumbsWrapper>
                              <SwiperWrapper
                                  onSwiper={setThumbsSwiper}
                                  spaceBetween={36}
                                  slidesPerView="auto"
                                  freeMode={true}
                                  watchSlidesProgress={true}
                                  modules={[FreeMode, Navigation, Thumbs]}
                                  className="mySwiperLower"
                              >
                                  {realization?.realizationGallery.map(
                                      (image, index) => (
                                          <SwiperSlide key={index}>
                                              {image.type === 'video' ? (
                                                  <ReactPlayer
                                                      url={image.video}
                                                      style={{
                                                          pointerEvents: 'none',
                                                      }}
                                                  ></ReactPlayer>
                                              ) : (
                                                  <Image
                                                      src={
                                                          image?.image
                                                              ?.sourceUrl
                                                      }
                                                  />
                                              )}
                                          </SwiperSlide>
                                      ),
                                  )}
                              </SwiperWrapper>
                          </ThumbsWrapper>
                      </ModalGalleryWrapper>
                  </Content>
              </ModalWrapper>,
              document.getElementById('portal') as HTMLElement,
          )
        : null;
};
