import styled, { css } from 'styled-components';
import { MotherWebsiteText } from 'styles/Typography.styled';
import { Swiper } from 'swiper/react';

export const ModalWrapper = styled.div`
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background: rgba(35, 47, 53, 0.7);
`;

export const Content = styled.div(
    ({ theme: { colors } }) => css`
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        background-color: ${colors.white};
        display: flex;
        max-width: 1225px;
        max-height: 100vh;
        padding: 68px 105px 50px;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    `,
);

export const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 38px;
    top: 38px;
`;

export const ModalHeader = styled.div`
    width: 100%;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
`;

export const TitleWrapper = styled.div`
    padding-bottom: 12px;
`;

export const ModalTitle = styled.h2(
    ({ theme: { colors, fontSizes, fontWeight } }) => css`
        padding-bottom: 12px;
        color: ${colors.darkBlue};
        font-size: ${fontSizes.f40};
        font-weight: ${fontWeight.semiBold};
        line-height: 54px;
    `,
);

export const AdressText = styled(MotherWebsiteText)(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        color: ${colors.motherText};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.m};
        line-height: 22px;
        text-transform: uppercase;
    `,
);

export const StyledText = styled(MotherWebsiteText)`
    max-width: 490px;
`;

export const ThumbsWrapper = styled.div`
    .swiper-wrapper {
        justify-content: center;
    }
`;
export const ModalGalleryWrapper = styled.div`
    max-width: 1015px;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        object-fit: cover;
        width: 100%;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .mySwiperUpper {
        max-height: 440px;
        width: 100%;
    }

    .mySwiperUpper .swiper-slide {
        height: 440px;
    }

    .mySwiperLower {
        box-sizing: border-box;
        margin: 24px 0 0 0;
    }

    .mySwiperLower .swiper-slide {
        cursor: pointer;
        width: 104px;
        height: 100px;

        &:after {
            content: '';
            background: rgba(1, 58, 87, 0.4);
            width: 100%;
            height: 100%;
            position: absolute;
        }
        &:hover {
            &:after {
                background: rgba(1, 58, 87, 0.4);
            }
        }
    }

    .mySwiperLower .swiper-slide-thumb-active {
        cursor: auto;

        &:after {
            display: none;
        }
    }

    .swiper-button {
        position: absolute;
        height: 32px;
        width: 32px;

        svg {
            height: 32px;
            width: 32px;
        }

        &:after {
            display: none;
        }
    }

    .swiper-button-next {
        display: block;
        right: 24px;
        margin-top: 0;
        transform: rotate(180deg);
    }

    .swiper-button-prev {
        left: 24px;
    }
`;

export const SwiperWrapper = styled(Swiper)``;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
