import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const MapWrapper = styled.div`
    width: 100%;
    height: 100%;
`;
